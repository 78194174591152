import React, { useState, useEffect } from 'react'
import Select from 'react-dropdown-select'
import { useSelector } from 'react-redux'
import { getCompanyEmployeeByRole } from '../../services/user'
import { UPDATEDPOST } from 'services/axios/common.api'

export default function AddParticipants({ companyId, onAddParticipants, userInfo }) {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])
  const { selectedRole } = useSelector((state) => state.user)
  console.log('selectedRole: ', selectedRole)
  const currentUser = useSelector((state) => state.user)
  console.log('currentUser: ', currentUser)
  console.log('userInfo :: ', userInfo)

  const userAgent = navigator.userAgent;

  useEffect(() => {
    let givenCompanyId = selectedRole?.CompanyID
    if (companyId) {
      givenCompanyId = companyId
    }
    setLoading(true)
    getCompanyEmployeeByRole(givenCompanyId, ['DOCTOR', 'STAFF', 'DOC']).then((result) => {
      console.log('AddParticipants result: ', result)
      if (result && result.length) {
        setOptions(
          result
            .map((item) => {
              console.log('item: ', item)
              const name = `${item.firstname} ${item.lastname}`
              return {
                name,
                label: name,
                value: item.id,
                ...item,
              }
            })
            .filter((item) => item.id !== selectedRole?.EmployeeID && item.role != "PATIENT"),
        )
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }, [])

  const handleAddParticipants = () => {
    if (typeof onAddParticipants === 'function') {

      console.log(selectedValues, "selectedValuesselectedValuesselectedValuesselectedValues")

      let invitedUsers = selectedValues?.map(({ id, email, cognitoid, role }) => ({
        id,
        email,
        cognitoid,
        role,
      }));
      


      let rawLogs = {
        "userIdentity": {
        "loggedinuser": `${currentUser?.email}`,
        "role":`${currentUser?.companyEmployee[0]?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "UPDATE",
        "source": "Add Participants",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "requestParameters": {
          invitedUsers
        },
      }
  

      let logBody = {
        // message: `User - ${currentUser?.sub} - Update Role - Clinic and Company Model Component`,
        message: rawLogs,
        logGroupName: "ONRx-Admin-Portal",
        logStreamName: `User - ${currentUser?.sub} - Clinic and Company Model Component`,
      }

      console.log(logBody, "logBodylogBodylogBodylogBody")
  
      logEvents(logBody);

      onAddParticipants(selectedValues)
    }
  }

  const logEvents = async (data) => {
    try {
        let result = await UPDATEDPOST(`log`, data);
        console.log("LOGGGGGGGGGGGG")
    } catch(e) {
        console.log(e, "ERRORRR")
    }
  }

  return loading ? (
    <div>
      <p>Loading</p>
    </div>
  ) : (
    <div className="add-participants-box">
      <Select
        multi
        options={options}
        values={selectedValues}
        onChange={(values) => setSelectedValues(values)}
      />
      <button
        type="button"
        onClick={() => {
          handleAddParticipants()
        }}
        className="margin-top-20 btn btn-sm btn-primary"
      >
        Add Participant
      </button>
    </div>
  )
}
