/* eslint-disable  vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-concat */
/* eslint-disable no-else-return */
import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode'
import * as moment from 'moment'
import store from 'store'
import config from '../../config'
import SSEHandler from '../../lib/SSEHandler'
import WebrtcCallHandler from '../../lib/WebrtcCallHandler'

const axios = require('axios')

// const BASEURL = `https://yk9s7at863.execute-api.ca-central-1.amazonaws.com/dev/`
const BASEURL = config.apiUrl

// axios.defaults.headers.common.Authorization = "Bearer sdjhjdjhdddjhd"
// axios.defaults.headers.common['Referrer-Policy'] = "no-referrer-when-downgrade";
const GET = async (path) => {
	await GetRefreshToken()
	return axios
		.get(`${config.apiUrl_old}${path}`, {
			headers: { Authorization: store.get('authToken') },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}

const POST = async (path, body) => {
	await GetRefreshToken()
	const options = {
		body: {},
	}
	if (body) {
		options.body = body
	}
	return axios
		.post(`${config.apiUrl_old}${path}`, options.body, {
			headers: { Authorization: store.get('authToken') },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}

const POSTWithoutToken = async (path, body) => {
	const options = {
		body: {},
	}
	if (body) {
		options.body = body
	}
	return axios.post(`${config.apiUrl_old}${path}`, options.body, {}).catch((error) => {
		if (error.response) {
			if (error.response.status === 401) {
				logout()
				// window.location = '/login'
			}
		}
		if (error.request) {
			// Auth.signOut()
			// window.location = '/login'
			throw error
		} else {
			throw error
		}
	})
}

const PUT = async (path, body) => {
	await GetRefreshToken()
	const options = {
		body: {},
	}
	if (body) {
		options.body = body
	}
	return axios
		.put(`${config.apiUrl_old}${path}`, options.body, {
			headers: { Authorization: store.get('authToken') },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}

const DELETE = async (path) => {
	await GetRefreshToken()
	return axios
		.delete(`${config.apiUrl_old}${path}`, {
			headers: { Authorization: store.get('authToken') },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}
const checkToken = () => {
	var duration = 0
	var tkn = store.get('authToken')
	// console.log("token", tkn);
	logstatus()
	if (tkn) {
		var storedtkn = jwt_decode(store.get('authToken'))
		// console.log(storedtkn);
		const date = moment.unix(storedtkn.exp)
		const now = moment(new Date())
		duration = date.diff(now, 'minutes')
		if (duration <= 0) {
			console.log('Token Expired', duration)
			return false
		} else {
			return true
		}
	}
	return true
}
const logstatus = () => {
	var lastlog = store.get('admin-lastcheckin')
		? moment(store.get('admin-lastcheckin'))
		: moment(new Date())
	const now = moment(new Date())
	const duration = lastlog.diff(now, 'minutes')
	// console.log(lastlog.format(), now.format(), duration);
	if (duration > 10) {
		var userid = store.get('userid')
		var name = store.get('name')
		var email = store.get('email')
		const payload = {
			activity: 'CHECKIN',
			source: 'ONRXADMIN',
			userid: userid,
			username: email,
			notes: '',
			valueeint: 1,
			value: 1,
			valuedesc: 'DRCHECKIN',
			sourceapp: 'ONRXADMIN',
			name: name,
		}
		// console.log(payload);
		try {
			POST('analyticsdb', payload).then((response) => {
				// console.log("ANALYTICS LOGGED - DR CHECKIN", response);
				store.set('admin-lastcheckin', moment(new Date()))
			})
		} catch (error) {
			console.log('ANALYTICS LOG ERROr', error)
		}
	}
}
const GetRefreshToken = async () => {
	store.set('userauth', true)
	var duration = 0
	try {
		// console.log(moment.unix(currentSession.idToken.payload.exp).format(), 'token expiration time')
		//  console.log(currentSession.idToken, 'token expiration time')
		// console.log(store);
		var storedtkn = jwt_decode(store.get('authToken'))
		// console.log(storedtkn);
		const date = moment.unix(storedtkn.exp)
		const now = moment(new Date())
		duration = date.diff(now, 'minutes')

		console.log('DURATION ', duration)
		if (duration <= 0) {
			//console.log('TIME OUT ************** ', duration)
			// window.location = '/login';
			logout()
		}

		if (duration <= 10) {
			console.log('TOKEN REFRESHED', duration)
			// Auth.currentSession()
			//     .then(data => console.log(data))
			//     .catch(err => console.log(err));
			const currentSession = await Auth.currentSession()
			const cognitoUser = await Auth.currentAuthenticatedUser()
			cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
				console.log('session', session)
				var userid = store.get('userid')
				var name = store.get('name')
				var email = store.get('email')
				const payload = {
					activity: 'REFRESHTOKEN',
					source: 'ONRXADMIN',
					userid: userid,
					username: email,
					notes: '',
					valueeint: 1,
					value: 1,
					valuedesc: 'REFRESHTOKEN',
					sourceapp: 'ONRXADMIN',
					name: name,
				}
				//console.log(payload)

				store.set('authToken', session?.idToken?.jwtToken)
				store.set('auth_token_exp', session?.idToken?.payload?.exp)

				// try {
				// 	POST('analyticsdb', payload).then((response) => {
				// 		console.log('ANALYTICS LOGGED - Token Refreshed', response)
				// 	})
				// } catch (error) {
				// 	console.log('ANALYTICS LOG ERROr', error)
				// }
			})
		}
	} catch (e) {
		console.log('Unable to refresh Token', e)
		logout()
		// history.push('/auth/login')
		// AuthService.logout();
		// store.set('userauth',false);
		// dispatch(authentication(false));
	}
}

function logout() {
	try {
		Auth.signOut().then((e) => {
			store.remove('cognitoResponse')
			store.remove('authToken')
			store.clearAll()
			// window.location = '/auth/login'
			WebrtcCallHandler.getInstance().cleanUp()
			SSEHandler.getInstance().cleanUp()
			window.location = '/'
		})
	} catch (error) {
		console.log('error signing out: ', error)
		// window.location = '/auth/login'
		window.location = '/'
	}
	// history.push('/login')
}

const UPDATEDPOST = async (path, body) => {
	await GetRefreshToken()
	const options = {
		body: {},
	}
	if (body) {
		options.body = body
	}
	return axios
		.post(`${BASEURL}${path}`, options.body, {
			headers: { Authorization: `Bearer ${store.get('authToken')}` },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}

const UPDATEDGET = async (path) => {
	await GetRefreshToken()
	return axios
		.get(`${BASEURL}${path}`, {
			headers: { Authorization: `Bearer ${store.get('authToken')}` },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}

const UPDATEDPUT = async (path, body) => {
	await GetRefreshToken()
	const options = {
		body: {},
	}
	if (body) {
		options.body = body
	}
	return axios
		.put(`${BASEURL}${path}`, options.body, {
			headers: { Authorization: `Bearer ${store.get('authToken')}` },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}

const UPDATEDDELETE = async (path) => {
	await GetRefreshToken()
	return axios
		.delete(`${BASEURL}${path}`, {
			headers: { Authorization: `Bearer ${store.get('authToken')}` },
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) {
					logout()
					// window.location = '/login'
				}
			}
			if (error.request) {
				// Auth.signOut()
				// window.location = '/login'
				throw error
			} else {
				throw error
			}
		})
}


export async function uploadFile(file, apiname) {
    const formData = new FormData();
    formData.append('report_data', file);

    try {
        const response = await fetch(`${apiname}`, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json'
            },
            mode: 'cors'
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('Failed to upload file');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export async function uploadFileForDr(file) {
	const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch('https://f0noc5q6o9.execute-api.ca-central-1.amazonaws.com/detect-dr-sim', {
            method: 'POST',
            body: formData,
            // headers: {
            //     'Accept': 'application/json',
			// 	'Authorization': store.get('authToken'),
			// 	'Access-Control-Allow-Origin' : '*'
            // },
            mode: 'cors'
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('Failed to upload file');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}


export { checkToken, GET, POST, PUT, DELETE, POSTWithoutToken, UPDATEDPOST, UPDATEDGET, UPDATEDDELETE, UPDATEDPUT }

